import React from "react";

const CompanyOverview = () => {
    return (
        <div className="bg-gray-100 p-4 md:p-12 mt-20">
            <div className="max-w-4xl mx-auto">
                <div className="aboutCompany mb-8">
                    <h2 className="text-3xl font-bold mb-5">About Company</h2>
                    {/* <div className="gradient-line mb-16"></div> */}
                    <p className="mt-3 text-gray-600">We have an honour to introduce <b>"NAVY LINE SHIP MANAGEMENT PVT. LTD."</b>, one of the most progressive Crew Manning agency, located in the city of Mumbai, India.</p>
                    <p className="mt-3 text-gray-600"><b>NAVY LINE SHIP MANAGEMENT PVT. LTD.</b> established on 10/03/2014 approved by <b>Director General of Shipping, Mumbai (Govt. of India)</b> & an <b>ISO 9001:2015</b> quality standard certified, We establish our process as per <b>RPSL Rules</b>, and in compliance with<b> MLC 2006</b>.</p>
                    <p className="mt-3 text-gray-600">NLSM is professionally managed by a well qualified ,skilled and experienced team who have made names for themselves in the Crew Manning and related Business, the main concentrated point of marine human resources in the world wide.</p>
                    <p className="mt-3 text-gray-600">We are proud to be a combination of traditional values of trust, sincerity, integrity, hard work and fair business practices together with modern technology oriented management system.</p>
                    <p className="mt-3 text-gray-600">A job in merchant marine considered an attractive carrier option to navy individual. The officers and ratings well trained accordingly to the latest <b>STCW</b> requirement. Our country had well qualified pool of navigating officers, engineers, and deck engine and catering department ratings. Our relationship with our seafarers is characterized by integrity, loyalty & lifelong commitment.</p>
                    <p className="mt-3 text-gray-600">NLSM long experience in maritime industry, promises the highest quality services. We recruit deck officers, engineer, deck and engine rating also catering crew to offshore vessel <b>(DP, AHTS, PSV, DSV, OIL, CHEMICAL, GAS )</b> container ships ro-ro vessels bulk and general cargo etc. Selection and recruitment of officers and crew pose significant challenges to any crewing agency because of the long drawn out process involved. here at NLSM crewing we engage all our available resources to these tasks so that our clients may be assured their vessels are in competent hands.</p>
                    <p className="mt-3 text-gray-600">Our state-of-the-art crewing database maintains comprehensive professional data on personnel that vessel managers can benefit from re-employing crew familiar with their vessels, operations, policies and procedures.</p>
                    <p className="mt-3 text-gray-600">Our crewing solutions revolve around the principle that our services should help a client improve their business performance. we assist you in achieving the desired objectives in a painless and efficient way. we take time and make the effort to understand the cultural, regulatory and environment context of a client's business so that we can deliver solutions that are best suited to their operational requirement.</p>
                </div>
                <div className="visions mb-8">
                    <h2 className="text-3xl font-bold mb-5">Vision & Mission</h2>
                    {/* <div className="gradient-line mb-16"></div> */}
                    <ul className="list-disc pl-6">
                        <li className="text-gray-600">To be ranked as the best provider of support services for Indian Seafarers, enhancing skills, enriching lives, and transforming values to highly responsibly promote safety of all crew members in all levels, considering primordial concern of the company.</li>
                        <li className="text-gray-600">To create a community of seafarers with life-long commitment to their families, providing for a Quality life, where each seafarer enjoys the fruit of his hard work from employment to retirement, and secures his future through the next generation.</li>
                        <li className="text-gray-600">To become a leading organization from the aspect of development, innovation and service quality to shipping industry to work towards enhancing associate satisfaction by meeting their requirement.</li>
                        <li className="text-gray-600">To establish close working relation with customers and associates, we do understand proper working of associates and the serve as per their need. We do provide and smooth functioning for our associates as per their need.To consistently maintain best training and development program for officers and crew thereby ensuring our Principal's satisfaction.</li>
                    </ul>

                </div>
                <div className="quality mb-8">
                    <h1 className="text-3xl font-bold mb-5">Quality</h1>
                    {/* <div className="gradient-line mb-16"></div> */}
                    <p className="text-gray-600">
                        We have a Quality Management System compliance with ISO 9001-2015 Standards in place. Also having an approval by<b> Director General of Shipping, Mumbai (Govt. of India)</b>.
                    </p>
                </div>
                <div className="quality mb-8">
                    <h1 className="text-3xl font-bold mb-5">Planning for Service Realization</h1>
                    {/* <div className="gradient-line mb-16"></div> */}
                    <p className="text-gray-600">
                        The organization plans & develops the processes needed for service realization. Planning of service realization is consistent with the requirements of the other interacting processes (Annex- A & B) of the Quality Management System.
                    </p>
                    <p className="text-gray-900">
                        In planning service realization, the organization determines the following:
                    </p>
                    <ul className="list-disc pl-6">
                        <li className="text-gray-600">
                            Quality objectives for setting up of targets of processes
                            requirements for the recruitment & placement
                        </li>
                        <li className="text-gray-600">
                            The need to establish processes, documents (Quality Manual, Procedure Manual, SOP & Formats) & provide resources (Human, Infrastructure & financial) specific to the service.
                        </li>
                        <li className="text-gray-600">
                            Required verification, validation, monitoring and measurement activities specific to the shipping management & placement services are carried out with appropriate control.

                        </li>
                        <li className="text-gray-600">
                            Records needed to provide evidence that the realization processes & resulting service meet requirements, is maintained in the requirement processing record.
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default CompanyOverview