// ContactUs.js
import React, { useState } from 'react';
import bgVdo from '../assets/background_vdo.mp4'
import message from '../assets/message.png'
import { collection, addDoc, serverTimestamp } from 'firebase/firestore';
import { firebasedb } from '../firebase/firebase';

const ContactUs = () => {
    const [formData, setFormData] = useState({
        name: '',
        contact: '',
        message: '',
    });
    const [submissionMessage, setSubmissionMessage] = useState(null);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({ ...prevData, [name]: value }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
    
        try {
            const timestamp = serverTimestamp();
            const formDataWithTimestamp = { ...formData, timestamp };
    
            const docRef = await addDoc(collection(firebasedb, 'messages'), formDataWithTimestamp);
    
            console.log('Document written with ID: ', docRef.id);
            setFormData({ name: '', contact: '', message: '' });
            setSubmissionMessage('Form submitted successfully! Will get back to you soon.');
        } catch (error) {
            console.error('Error adding document: ', error);
        }
    };

    return (
        <div className="relative h-screen mt-10">
            {/* Video Background */}
            <video className="absolute inset-0 w-full h-full object-cover" autoPlay muted loop>
                <source src={bgVdo} type="video/mp4" />
            </video>
            <div className="absolute inset-0 bg-black opacity-50"></div>
            {/* Content */}
            <div className="relative z-10 flex items-center justify-center h-full">
                <div className="bg-gray-300 p-8 rounded-lg shadow-lg max-w-lg w-full">
                    <img src={message} alt="Message_logo" width='50px' />
                    {submissionMessage ? (
                        <div className="mt-4 text-green-700 mb-4">
                            {submissionMessage}
                        </div>
                    ) : (
                        <p className="text-gray-600 text-xs font-bold mb-4 mt-4">
                            Have a question or a suggestion? We'd love to hear from you! Fill out the form below, and we'll get back to you as soon as possible. We respond to everyone, and your feedback is valuable to us.
                        </p>
                    )
                    }

                    <form onSubmit={handleSubmit}>
                        {/* Name Field */}
                        <div className="mb-4">
                            <label htmlFor="name" className="block text-gray-600 text-sm font-semibold mb-2">
                                Name
                            </label>
                            <input
                                required
                                type="text"
                                id="name"
                                name="name"
                                value={formData.name}
                                onChange={handleInputChange}
                                className="w-full px-4 py-2 border rounded-md focus:outline-none focus:border-blue-500"
                            />
                        </div>

                        {/* Contact Field */}
                        <div className="mb-4">
                            <label htmlFor="contact" className="block text-gray-600 text-sm font-semibold mb-2">
                                Contact
                            </label>
                            <input
                                required
                                type="text"
                                id="contact"
                                name="contact"
                                value={formData.contact}
                                onChange={handleInputChange}
                                className="w-full px-4 py-2 border rounded-md focus:outline-none focus:border-blue-500"
                            />
                        </div>

                        {/* Message Field */}
                        <div className="mb-6">
                            <label htmlFor="message" className="block text-gray-600 text-sm font-semibold mb-2">
                                Message
                            </label>
                            <textarea
                                required
                                id="message"
                                name="message"
                                rows="4"
                                value={formData.message}
                                onChange={handleInputChange}
                                className="w-full px-4 py-2 border rounded-md focus:outline-none focus:border-blue-500"
                            ></textarea>
                        </div>


                        {/* Submit Button */}
                        <button
                            type="submit"
                            className="bg-blue-500 text-white px-4 
                            py-2 rounded-md w-full hover:bg-blue-600
                            disabled:bg-gray-600
                             focus:outline-none focus:shadow-outline-blue active:bg-blue-800"
                            disabled={submissionMessage}
                        >
                            Submit
                        </button>
                    </form>

                </div>
            </div>
        </div>
    );
};

export default ContactUs;
