import React, { useEffect, useState } from "react";
import crewManning1 from '../assets/ourServices/crewManning1.jpg'
import crewManning2 from '../assets/ourServices/crewManning2.webp'
import vesselDelivery1 from '../assets/ourServices/vesselDelivery1.jpg'
import vesselDelivery2 from '../assets/ourServices/vesselDelivery2.jpg'
import mechanicalWorks1 from '../assets/ourServices/mechanicalServices1.jpg'
import mechanicalWorks2 from '../assets/ourServices/mechanicalServices2.jpg'
import technicalManagement1 from '../assets/ourServices/technicalManagement1.webp'
import technicalManagement2 from '../assets/ourServices/technicalManagement2.jpg'
import marineDocumentation1 from '../assets/ourServices/marineDocumentation1.jpeg'
import marineDocumentation2 from '../assets/ourServices/marineDocumentation2.jpg'
import { useLocation } from "react-router-dom";

const ServicePage = () => {
    const location = useLocation()
    const [currentService, setCurrentService] = useState('crewManning');

    useEffect(() => {
        const targetSection = location.state?.section || "crewManning";
        const element = document.getElementById(targetSection);
        if (element) {
            const yOffset = -100;
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;

            window.scrollTo({ top: y, behavior: 'smooth' });
        }
    }, [location]);


    const handleScroll = () => {
        const scrollPosition = window.scrollY;
        const serviceSections = ["crewManning", "vesselDelivery", "mechanicalWorks", "technicalManagement", "marineDocumentation"];
        let currentService = "crewManning";

        for (const section of serviceSections) {
            const element = document.getElementById(section);
            if (element && element.offsetTop <= scrollPosition + 200) {
                currentService = section;
            }
        }

        setCurrentService(currentService);
    };

    React.useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    const assets = {
        crewManning: [crewManning1, crewManning2],
        vesselDelivery: [vesselDelivery1, vesselDelivery2],
        mechanicalWorks: [mechanicalWorks1, mechanicalWorks2],
        technicalManagement: [technicalManagement1, technicalManagement2],
        marineDocumentation: [marineDocumentation1, marineDocumentation2]
    }

    return (
        <div className="flex flex-col md:flex-row min-h-screen">
            <div className="flex flex-col items-center justify-center md:fixed top-0 left-0 h-full mt-10 md:ml-4 md:ml-20 w-full md:w-1/4">
                <div className={`image-container md:block`}>
                    <img src={assets[currentService][0]} alt="Vessel Delivery" className="w-80 h-80 object-cover border p-2" />
                </div>
                <div className={`image-container md:block`}>
                    <img src={assets[currentService][1]} alt="Mechanical Works" className="w-80 h-80 object-cover border p-2" />
                </div>
            </div>
            <div className="flex-1 max-w-4xl w-full md:ml-96 mt-10 p-4">
                <div className="text-center mb-8 md:mt-10">
                    <h1 className="text-2xl md:text-5xl font-bold">Our Services</h1>
                    <p className="text-gray-600">Providing exceptional services for your needs</p>
                </div>
                <div id="crewManning" className={`service mb-8 ${currentService === "crewManning" ? "active" : ""}`}>
                    <h2 className="text-2xl md:text-3xl font-semibold mb-4">Crew Manning</h2>
                    <p className="mb-4">Crew Management is our Primary business and we take our performance levels in providing Crew Manning Services very seriously.
                        We have a Quality Management System compliant with ISO 9001-2015 Standards.</p>
                    <p className="mb-4">Combination of highly skilled and experienced management, operational, technical and human resources staff,
                        we blieve our company offers our customers a complete and professional seafarer's.</p>
                    <p className="mb-4">We humbly handle the manning requirements of various Indian/International ship owners and have seafarers in our pool.
                        We undertake the placement needs of our clients by catering to all the ranks.</p>
                    <p className="mb-4">The recruitment method involves the process of screening, interviewing, selection and briefing, in the order to find the right fit for every vessels and candidate. during this process,
                        all our personnel are made by fully aware of their professional obligations and responsibilities during the engagement.</p>
                    <p className="mb-4">Before including a candidate in our database or updating their records, the seafarer’s qualifications and experience are verified and cross- referenced with past employers, government agencies and other organizations to ensure authenticity.
                        professional attitude and conduct are thoroughly assessed during personal interview.</p>
                    <h3 className="text-lg font-semibold mt-10 mb-2">Recruitment Procedure:</h3>
                    <ul className="list-disc pl-6">
                        <li> Sourcing (Data Base, Job Board on Website).</li>
                        <li> Screening (Social Media, and Local Media).</li>
                        <li>Evaluation (Interview and Assessment).</li>
                        <li> Verification (Documents, Back Ground and References Check).</li>
                        <li>Finally, Selection and Presentation Candidate to Employer.</li>
                        <li> Contract information date of joining and period of contract.</li>
                        <li> Assistance in Hiring Signing Contract of Employment, Travel Arrangement N Flag State Documents Arrangement’s.</li>
                        {/* Add more steps if needed */}
                    </ul>
                </div>

                {/* Service 2: Vessel Delivery */}
                <div id="vesselDelivery" className={`service mb-8 ${currentService === "vesselDelivery" ? "active" : ""}`}>
                    <h2 className="text-2xl md:text-3xl font-semibold mb-4">Vessel Delivery</h2>
                    <p className="mb-4">
                        To meet the principal's urgent need, we can promptly organize experience officer and crew members who will efficiently
                        deliver any types of vessels from any point of origin to any point of destination.
                    </p>
                </div>

                <div id="mechanicalWorks" className={`service mb-8 ${currentService === "mechanicalWorks" ? "active" : ""}`}>
                    <h2 className="text-2xl md:text-3xl font-semibold mb-4">Mechanical Works</h2>
                </div>
                <div id="technicalManagement" className={`service mb-8 ${currentService === "mechanicalWorks" ? "active" : ""}`}>
                    <h2 className="text-2xl md:text-3xl font-semibold mb-4">Technical Management </h2>
                    <p className="mb-4">
                        We are also pioneers in providing Repair Team members & also Ship Building / Repair staff such as Welder / Fitters / Fabricators / Mechanics / Welder Cutter / Engine Fitter / General Cleaning Crew as & when required
                        / Painter etc.All crew holds necessary documents.
                    </p>
                </div>
                <div id="marineDocumentation" className={`service mb-8 ${currentService === "marineDocumentation" ? "active" : ""}`}>
                    <h2 className="text-2xl md:text-3xl font-semibold mb-4">Marine Documentation</h2>
                </div>
            </div>
        </div>
    );
};

export default ServicePage;
