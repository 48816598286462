import React from "react";

const Career = () => {
    return (
        <div className="bg-gray-100 min-h-screen font-serif mt-20 md:px-20">
            {/* Header */}
            <header className="py-6 text-center">
                <h1 className="text-3xl md:text-4xl font-bold">Career in Merchant Navy</h1>
            </header>

            {/* Main Content */}
            <main className="container mx-auto px-4 py-8">
                {/* Opportunities Section */}
                <section className="mb-12">
                    <h2 className="text-xl md:text-2xl font-semibold mb-4">Opportunities in Merchant Navy</h2>
                    <p className="text-lg text-gray-600">
                        The opportunity to travel around the world and the lure of adventure on the high seas, besides the possibility of high
                        remunerations attracts many youngsters to make a career in the Merchant Navy. Merchant Navy is different from the navy
                        in the sense that it offers commercial services, as opposed to the navy, which is largely involved in the defence of a nation.          </p>
                    {/* Include more detailed content as needed */}
                </section>

                {/* Eligibility Criteria Section */}
                <section className="mb-12">
                    <h2 className="text-xl md:text-2xl font-semibold mb-4">Eligibility Criteria</h2>
                    <ul className="text-lg list-disc pl-6">
                        <li className="text-gray-600">10th for GP rating Course.</li>
                        <li className="text-gray-600">For Deck cadet and Engine cadet: 10+2 with PCM 60%.</li>
                    </ul>
                    <p className="mt-8 text-lg">There are enormous employment opportunities in this field. Candidates who have finished the basic course in merchant navy
                        can get jobs very easily through NLSM.</p>
                </section>

                {/* Merchant Navy Fleet Section */}
                <section className="mb-12">
                    <h2 className="text-xl md:text-2xl font-semibold mb-4">Merchant Navy Fleet</h2>
                    <p className="text-lg  text-gray-600">
                        Bulk career / General Cargo / Container / Chemical Tanker / Gas Tanker / Oil Tanker / Passanger RO-RO-Vessel /
                        Refership / Fishing Vessel barges / Dry Cargo Bulk / Barges Liquid Type / Offshore Supply Vessel.
                        Tugs of various services.                    </p>
                    {/* Add more details about the fleet */}
                </section>

                {/* Job Profiles Section */}
                <section className="px-8 md:px-5">
                    <h2 className="text-xl md:text-2xl font-semibold mb-4">Merchant Navy Job Profiles</h2>
                    <ul className="list-disc">
                        <li className="text-xl text-gray-600"><u>Deck officers</u>: -</li>
                        <p className="mt-2 text-lg text-gray-600">Master / Chief officer / 2nd Officer / 3rd Officer / Cadet / Radio Officer.</p>
                        <li className="text-xl mt-7 text-gray-600"><u>Engineers</u>: -</li>
                        <p className="mt-2 text-lg text-gray-600">Chief Engineer / 2nd Engineer / 3rd Engineer / 4th Engineer / 5th Engineer (TME) / Electrical Officer.</p>
                        <li className="text-xl mt-7 text-gray-600"><u>Deck Ratings: -</u>: -</li>
                        <p className="mt-2 text-lg text-gray-600">Bosun / Able-Bodied Seaman / Ordinary Seaman / Pumpman .</p>
                        <li className="text-xl mt-7 text-gray-600"><u>Engine Ratings: -</u>: -</li>
                        <p className="mt-2 text-lg text-gray-600">Fitter / Oiler / Wiper .</p>
                        <li className="text-xl mt-7 text-gray-600"><u>Saloon Ratings: -</u>: -</li>
                        <p className="mt-2 text-lg text-gray-600">Chief Cook / 2nd Cook / Chief Steward / General Steward .</p>
                    </ul>
                </section>
            </main>
        </div>
    );
};

export default Career;
