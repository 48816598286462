import React from 'react';
import AboutUsImage from '../../assets/AboutUs.jpg'
import { Link } from 'react-router-dom';

const AboutUs = () => {
    return (
        <section id='aboutUs' className="bg2 flex items-center bg-white xl:h-1000 font-poppins py-10">
            <div className="justify-center flex-1 max-w-7xl py-4 mx-auto lg:py-6 md:px-6">
                <div className="flex flex-wrap justify-between">
                    <div className="w-100 px-5 mb-10 lg:w-1/2 lg:mb-0">
                        <img src={AboutUsImage} alt="About Company"
                            className="relative z-40 object-cover w-full h-96 rounded-3xl" />
                    </div>
                    <div className="w-full px-5 mb-10 lg:w-1/2 lg:mb-0 ">
                        <h1 className="text-4xl sm:text-1xl md:text-4xl lg:text-6xl text-gray-900 font-extrabold text-center mb-8">
                            <span className="inline-block p-2 text-gray-300">&ldquo;</span>
                            <span className="inline-block text-gray-100">About Us</span>
                            <span className="inline-block p-2 text-gray-300">&rdquo;</span>
                        </h1>
                        <p className="mb-10 text-base  text-gray-200">
                            <p className='text-lg font-bold'>Established in 2014,</p>
                            <b>NAVY LINE SHIP MANAGEMENT PVT. LTD.</b> established on 10/03/2014 approved by
                            <b> Director General of Shipping, Mumbai (Govt. of India) & an ISO 9001:2015. </b>
                            NLSM is professionally managed by well qualified, skilled & experienced teams who have carved a niche for
                            themselves in the crew manning, fabrication and related businesses, the main concentrated point of marine human resources in the world wide.
                            We are proud to have a combination of traditional values of trust, sincerity, integrity, hard work and fair business practices together with
                            a completely modern, technology oriented management system.
                        </p>
                        <Link to="company-overview"
                            className="px-4 py-3 text-blue-300 transition-all transform border border-blue-300 rounded-3xl hover:bg-navyBlue hover:text-gray-100">
                            Know more...
                        </Link>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default AboutUs;
