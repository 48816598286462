import './App.css';
import { Route, Routes } from "react-router-dom";
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import LandingPage from './pages/LandingPage';
import CompanyOverview from './pages/CompanyOverview';
import Career from './pages/Career';
import ServicePage from './pages/ServicePage';
import ContactUs from './pages/ContactUs';
import CertificatePage from './pages/CertificatePage';
import TeamPage from './pages/TeamPage';
import ScrollToTop from './components/ScrollToTop';

function App() {
  return (
    <div>
      <Navbar />
      <ScrollToTop />
      <Routes>
        <Route index element={<LandingPage />} />
        <Route path="/company-overview" element={<CompanyOverview />} />
        <Route path="/career" element={<Career />} />
        <Route path="/services" element={<ServicePage />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/certificates" element={<CertificatePage />} />
        <Route path="/team" element={<TeamPage />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
