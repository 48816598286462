import React from 'react';
import Neeraj_Pathak from '../../assets/team/Neeraj_Pathak.jpeg'
import Jahnavi_Pathak from '../../assets/team/Jahnavi_Pathak.jpeg'

const Team = () => {
    // Sample data for the management team
    const teamMembers = [
        {
            id: 1,
            name: 'Mr. Niraj Pathak',
            position: 'Managing Director',
            about: 'Started his career as a Mariner and later on shore started his own business named Navyline Ship Management Pvt. Ltd. He is also founder of DSP Memorial Foundation.',
            imageUrl: Neeraj_Pathak
        },
        {
            id: 2,
            name: 'Mrs. Jahnavi Pathak',
            position: 'Director',
            about: "PGDM in Ship Management. She has been involved in this business since 2013, also holds a position of Director in DSP Memorial Foundation. Member of SheEo, (Women's in Maritime, India).",
            imageUrl: Jahnavi_Pathak
        }
    ];

    return (
        <section className="bg-gray-300" id='team'>
            <div className="py-8 px-4 md:px-20 mx-auto max-w-screen-xl lg:py-16 lg:px-6 ">
                <div className="mx-auto max-w-screen text-center mb-8 lg:mb-16">
                    <h1 className="text-2xl md:text-4xl lg:text-6xl text-gray-900 font-extrabold text-center mb-8">
                        <span className="inline-block p-2 text-gray-700">&ldquo;</span>
                        <span className="inline-block text-navyBlue">Meet Our Directors</span>
                        <span className="inline-block p-2 text-gray-700">&rdquo;</span>
                    </h1>
                </div>
                <div className="grid gap-12 mb-6 lg:mb-16 md:grid-cols-2">
                    {
                        teamMembers.map(member => (
                            <div className="items-center bg-gray-900 rounded-lg shadow sm:flex">
                                <img className="rounded-lg sm:rounded-none sm:rounded-l-lg" width='200px' src={member.imageUrl} alt={member.name} />
                                <div className="p-5">
                                    <h3 className="text-xl font-bold tracking-tight text-gray-100">
                                        <a href="#">{member.name}</a>
                                    </h3>
                                    <span className="text-gray-200">{member.position}</span>
                                    <p className="mt-3 font-light mb-4 text-sm text-gray-200">{member.about}</p>
                                </div>
                            </div>

                        ))
                    }
                </div>
            </div>
        </section>
    );
};

export default Team;
