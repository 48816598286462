import React from 'react';
import halani from '../../assets/nlsm/halani.jpg'
import sadhav from '../../assets/nlsm/sadhav.png'
import ocean_sparkle from '../../assets/nlsm/ocean_sparkle.png'

const Testimonials = () => {
    const testimonials = [
        {
            id: 1,
            name: 'Sadhav Shipping Limited',
            comment: 'Located in Mumbai having two branch offices and an head office at Loha Bhavan Mumbai. Established in year 1992. Sadhav owns Offshore  Vessels, Dry Dock Barges, Tanker Barges and High Speed Boats.Sadhav Shipping is our Client since 2014.',
            avatarUrl: sadhav
        },
        {
            id: 2,
            name: 'Halani Shipping Private Limited',
            comment: 'Halani Shipping is a group company of Bhambhani Limited. They own (DP1, DP2,OSV, PSV several types of vessels,Tugs and Barges. NLSM and Halani Shipping are in contact since January 2017.',
            avatarUrl: halani
        },
        {
            id: 3,
            name: 'Ocean Sparkle Limited',
            comment: "Ocean Sparkle Limited (OSL) is India's leading port operations and marine services company. They own several types of vessels. We are in manning with Ocean since September 2018.",
            avatarUrl: ocean_sparkle
        }
    ];

    return (
        <div className="container mx-auto py-12 px-4 md:px-20" id='testimonials'>
            <h1 className="text-2xl md:text-4xl lg:text-6xl text-gray-900 font-extrabold text-center mb-8">
                <span className="inline-block p-2 text-yellow-100">&ldquo;</span>
                <span className="inline-block text-yellow-50">Tie Up Companies</span>
                <span className="inline-block p-2 text-yellow-100">&rdquo;</span>
            </h1>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
                {testimonials.map((testimonial) => (
                    <div
                        key={testimonial.id}
                        className="testimonial-card bg-gray-200 rounded-lg shadow-md p-6"
                    >
                        <div className="flex items-center mb-4">
                            <img
                                src={testimonial.avatarUrl}
                                alt={testimonial.name}
                                width='100px'
                            />
                            <div className='ml-5'>
                                <h3 className="text-lg font-semibold">{testimonial.name}</h3>
                            </div>
                        </div>
                        <p className="text-gray-700 text-sm">{testimonial.comment}</p>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Testimonials;
