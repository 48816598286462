import React from 'react';
import certificate1 from '../assets/certificates/certificate1.png'
import certificate2 from '../assets/certificates/certificate2.png'
import certificate3 from '../assets/certificates/certificate3.png'
import certificate4 from '../assets/certificates/certificate4.png'

const certificates = [
    { id: 1, title: 'Certificate Title 1', image: certificate1 },
    { id: 2, title: 'Certificate Title 2', image: certificate2 },
    { id: 2, title: 'Certificate Title 2', image: certificate3 },
    { id: 2, title: 'Certificate Title 2', image: certificate4 }
];

const CertificatePage = () => {
    return (
        <div className="bg-gray-800 min-h-screen flex flex-col items-center justify-center py-20">
            <h2 className="text-3xl text-gray-100 font-bold mb-6 mt-10">
                Our Certificates
            </h2>
            <div className="max-w-screen-xl grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-8 p-8">
                {certificates.map((certificate) => (
                    <div key={certificate.id} className="bg-white p-6 rounded-lg shadow-md">
                        <img src={certificate.image} alt={certificate.title} className="w-full h-200 object-cover mb-4 rounded-md" />
                    </div>
                ))}
            </div>
        </div>
    );
};

export default CertificatePage;
