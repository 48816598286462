import Hero from './Hero';
import AboutUs from './AboutUs';
import Services from './Services';
import Team from './Team';
import Testimonials from './Testimonials';

function LandingPage() {
  return (
    <div className=''>
      <Hero />
      <AboutUs />
      <Services />
      <Team />
      <Testimonials />
    </div>
  );
}

export default LandingPage;
