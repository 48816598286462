import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <div className="flex flex-col sm:flex-row" id='contact'>
      {/* Contact Us */}
      <div className="w-full sm:w-1/2 bg-gray-900 text-white z-10">
        <div className="container mx-auto py-6 px-4 md:ml-10">
          <div className="mb-4">
            <h3 className="text-lg font-semibold mb-2">General Information :</h3>
            <p className='text-gray-300'>Email: <a href="mailto:info@navylineship.com" className="text-blue-500">info@navylineship.com</a></p>
          </div>
          <div className="mb-4">
            <h3 className="text-lg font-semibold mb-2">Resume Submissions :</h3>
            <p className='text-gray-300'>Email: <a href="mailto:crewing@navylineship.com" className="text-blue-500">crewing@navylineship.com</a></p>
          </div>
          <div className="mb-4">
            <h3 className="text-lg font-semibold mb-2">Contact Numbers :</h3>
            <p className='text-gray-300'>Phone: +91-022-66370937/38</p>
            <p className='text-gray-300'>Mobile: +91 - 9820123738</p>
          </div>
          <div class="text-gray-400 text-sm mt-8">
            <p>&copy; 2023 <a className='underline' href="navylineship.com">Navyline Ship pvt. ltd.</a> All rights reserved.</p>
          </div>
        </div>
      </div>

      {/* Footer */}
      <div className="flex flex-col justify-between w-full sm:w-1/2 bg-gray-800 text-white py-4 md:py-6 px-4">
        <div className="container mx-auto flex flex-row">
          <ul className="flex justify-start flex-col gap-5 md:ml-10">
            <li><Link to="/" className="hover:text-blue-500">Home</Link></li>
            <li><Link to="services" className="hover:text-blue-500">Services</Link></li>
            <li><Link to="career" className="hover:text-blue-500">Career</Link></li>
            <li><Link to="team" className="hover:text-blue-500">Our Team</Link></li>
            <li><Link to="career" className="hover:text-blue-500">Explore Career In Merchant Navy</Link></li>
            {/* Add more footer links as needed */}
          </ul>
          <ul className="flex justify-start flex-col md:ml-5 gap-5">
            <li><Link to="career" className="hover:text-blue-500">Check Eligibility</Link></li>
            <li><Link to="certificates" className="hover:text-blue-500">Certificates</Link></li>
            <li><Link to="contact-us" className="hover:text-blue-500">Contact Us</Link></li>
            <li><Link to="company-overview" className="hover:text-blue-500">Company Overview</Link></li>
            {/* Add more footer links as needed */}
          </ul>
        </div>
        <div className="flex justify-center mt-20">
          <small className='text-gray-300 text-center'>Dreamed, Doodled and Developed by <br />
            <a className='underline text-blue-200' target='_blank' href="https://niteshvishwakarma.netlify.app">  Nitesh Vishwakarma</a>
          </small>
        </div>
      </div>

    </div>
  );
};

export default Footer;
