import React from 'react';
import Harish from '../assets/team/Harish.png'
import Jahnvi_Pathak from '../assets/team/Jahnavi_Pathak.jpeg'
import Neeraj_Pathak from '../assets/team/Neeraj_Pathak.jpeg'
import Rohit from '../assets/team/Rohit.jpeg'

const teamMembers = [
    {
        id: 1,
        name: 'Mr. Niraj Pathak',
        role: 'Managing Director',
        description: 'Started his career as a Mariner and later on shore started his own business named Navyline Ship Management Pvt. Ltd. He is also founder of DSP Memorial Foundation.',
        image: Neeraj_Pathak,
    },
    {
        id: 2,
        name: 'Mrs. Jahnavi Pathak',
        role: 'Director',
        description: "PGDM in Ship Management. She has been involved in this business since 2013, also holds a position of Director in DSP Memorial Foundation. Member of SheEo, (Women's in Maritime, India).",
        image: Jahnvi_Pathak,
    },
    {
        id: 3,
        name: 'Mr. Harish Pawar',
        role: 'General Manager',
        description: 'His career started as Deck Cadet in June 1990 & left  sea life as Ch Off in Dec 2012. Was an instructor for 6 months at YAK institute, Khopoli. From June 2013-July 2023 Independent crewing Manager at Sadhav Shipping Ltd., and since then, working with NLSM.',
        image: Harish,
    },
    {
        id: 4,
        name: 'Mr. Rohit More',
        role: 'Fleet Personnel',
        description: 'Mr. Rohit More joined the company as a Fleet Personnel in January 2024. He has been associated for over 12 years in the maritime industry as a Crew Manager. Prior to joining Navy Line Ship Management Pvt. Ltd., he worked for ICCSA, SEASTAR Ship, and MSC Shipping as a Crew Manager and 2 years of experience in ship breaking company. He has done MBA in Finance from GNIMS Institute and PGDM in Shipping from NMIS Institute of shipping.',
        image: Rohit,
    }
];

const TeamPage = () => {
    return (
        <div className="bg-gray-100 bg3 min-h-screen flex flex-col items-center justify-center py-20">
            <h2 className="text-5xl text-white font-bold mb-6 mt-10">Our Team</h2>
            <div className="max-w-screen-xl grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-8 p-8">
                {teamMembers.map(member => (
                    <div className="items-center bg-gray-100 rounded-lg shadow sm:flex cursor-pointer hover:bg-gray-200">
                        <img className="rounded-lg sm:rounded-none sm:rounded-l-lg" width='200px' src={member.image} alt={member.name} />
                        <div className="p-5">
                            <h3 className="text-xl font-bold tracking-tight text-gray-900">
                                <a href="#">{member.name}</a>
                            </h3>
                            <span className="text-gray-600 font-medium">{member.role}</span>
                            <p className="mt-3 mb-4 text-sm text-gray-700">{member.description}</p>
                        </div>
                    </div>

                ))}
            </div>
        </div>
    );
};

export default TeamPage;
