import React from 'react';
import crew_manning from '../../assets/ourServices/crew_manning.png'
import vessel_delivery from '../../assets/ourServices/vessel_delivery.png'
import mechanical_work from '../../assets/ourServices/mechanical_service.png'
import technical_management from '../../assets/ourServices/technical_management.png'
import marine_documentation from '../../assets/ourServices/marine_documentation.png'
import { Link, useNavigate } from 'react-router-dom';

const Services = () => {

    const navigate = useNavigate()

    const navigateSection = (section) => {
        navigate('/services', { state: { section: section } })
    }

    const services = [
        {
            id: 1,
            title: 'Crew Manning',
            description: 'Recruiting and managing seafarers for vessel roles, ensuring compliance, safety, and efficiency through careful screening and deployment.',
            icon: crew_manning,
            section: 'crewManning'
        },
        {
            id: 2,
            title: 'Vessel Delivery',
            description: 'Expert delivery services for vessels, ensuring secure and timely transport by skilled professionals in the merchant navy.',
            icon: vessel_delivery,
            section: 'vesselDelivery'
        },
        {
            id: 3,
            title: 'Mechanical Works',
            description: 'Precision mechanical services, enhancing vessel performance and reliability through specialized expertise in the merchant navy.',
            icon: mechanical_work,
            section: 'mechanicalWorks'
        },
        {
            id: 4,
            title: 'Technical Management',
            description: ' Efficient technical management solutions for maritime operations, optimizing vessel performance and compliance in the merchant navy.',
            icon: technical_management,
            section: 'technicalManagement'
        },
        {
            id: 5,
            title: 'Marine Documentation',
            description: ' Streamlined marine documentation services, ensuring compliance with regulations and efficient record-keeping in the merchant navy.',
            icon: marine_documentation,
            section: 'marineDocumentation'
        },
        // Add more services as needed
    ];
    return (
        <div className="container mx-auto bg-gray-200 py-12 px-4 md:px-20" id='services'>
            <h1 className="text-4xl md:text-5xl lg:text-6xl text-gray-900 font-extrabold text-center mb-8">
                <span className="inline-block p-2 text-gray-700">&ldquo;</span>
                <span className="inline-block text-navyBlue">Our Services</span>
                <span className="inline-block p-2 text-gray-700">&rdquo;</span>
            </h1>

            <div className="grid grid-cols-1 md:grid-cols-5 gap-6">
                {services.map((service) => (
                    <div className="bg-white rounded-lg p-3 shadow-md
                    cursor-pointer md:hover:scale-110 transition duration-300 ease-out"
                        onClick={() => navigateSection(service.section)}
                    >
                        <img className='w-10' src={service.icon} alt={service.title} />
                        <h2 className="text-l md:text-xl font-semibold mb-2">{service.title}</h2>
                        <p className="text-gray-600 text-sm mb-2">{service.description}</p>
                        <Link className='text-sm text-blue-800' onClick={() => navigateSection(service.section)}>Learn More...</Link>
                    </div>
                ))}
            </div>
        </div>

    );
};

export default Services;
