import React, { useState, useEffect } from 'react';
import { Link as ScrollLink } from "react-scroll";
import navyline_logo from '../assets/navyline_logo.jpeg'
import { Link as RouterLink, useNavigate } from 'react-router-dom';

const Navbar = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [prevScrollPos, setPrevScrollPos] = useState(0);
    const [visible, setVisible] = useState(true);
    const navigate = useNavigate()


    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    useEffect(() => {
        const handleScroll = () => {
            const currentScrollPos = window.pageYOffset;
            const visible = prevScrollPos > currentScrollPos || currentScrollPos < 10;

            setPrevScrollPos(currentScrollPos);
            setVisible(visible);
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [prevScrollPos]);

    const navbarStyle = {
        transition: 'top 0.3s',
        top: visible ? '0' : '-100px',
        position: 'fixed',
        width: '100%',
        zIndex: '1000',
    };

    return (
        <nav className="bg-slate-100 py-3" style={navbarStyle}>
            <div className="container mx-auto px-4">
                <div className="flex justify-between items-center">
                    <div className="text-white text-lg font-semibold cursor-pointer flex items-center">
                        <RouterLink to='/'>
                            <img src={navyline_logo} alt="Company Logo" className='w-12' />
                        </RouterLink>
                        <h2 className="ml-3 font-bold text-xs md:text-lg text-cyan-600">NAVY LINE SHIP MANAGEMENT PVT. LTD.</h2>
                    </div>

                    <div className="lg:hidden">
                        <button
                            onClick={toggleMenu}
                            className="text-black"
                        >
                            <svg
                                className="h-6 w-6 fill-current"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                            >
                                <path
                                    className={isOpen ? 'hidden' : 'block'}
                                    fillRule="evenodd"
                                    d="M3 18h18v-2H3v2zM3 12h18v-2H3v2zM3 6h18V4H3v2z"
                                />
                                <path
                                    className={isOpen ? 'block' : 'hidden'}
                                    fillRule="evenodd"
                                    d="M4.293 18.707l16-16-1.414-1.414-16 16 1.414 1.414zM19 17.414L5.414 4H7V2H3v4h2.586L19 15.586V14h2v4h-4v-2z"
                                />
                            </svg>
                        </button>
                    </div>
                    <div className="hidden lg:flex lg:flex-row items-center space-x-10">
                        <RouterLink to="company-overview" className="navLinks" smooth={true} duration={1000}>About Us</RouterLink>
                        <RouterLink to="services" className="navLinks" smooth={true} duration={1000}>Services</RouterLink>
                        <RouterLink to="team" className="navLinks" smooth={true} duration={1000}>Our Team</RouterLink>
                        <RouterLink to="certificates" className="navLinks" smooth={true} duration={1000}>Certificates</RouterLink>
                        <RouterLink to="contact-us" className="navLinks" smooth={true} duration={1000}>Contact Us</RouterLink>
                    </div>
                </div>
                {/* Mobile Menu */}
                {isOpen && (
                    <div className="lg:hidden mt-4">
                        <RouterLink onClick={toggleMenu} to="company-overview" className="cursor-pointer  block py-2" smooth={true} duration={1000}>About Us</RouterLink>
                        <RouterLink onClick={toggleMenu} to="services" className="cursor-pointer  block py-2" smooth={true} duration={1000}>Services</RouterLink>
                        <RouterLink onClick={toggleMenu} to="team" className="cursor-pointer  block py-2" smooth={true} duration={1000}>Our Team</RouterLink>
                        <RouterLink onClick={toggleMenu} to="certificates" className="cursor-pointer  block py-2" smooth={true} duration={1000}>Certificates</RouterLink>
                        <RouterLink onClick={toggleMenu} to="contact-us" className="cursor-pointer  block py-2" smooth={true} duration={1000}>Contact Us</RouterLink>
                        {/* <a href="#gallery" className="block text-white py-2">Image Gallery</a> */}
                    </div>
                )}
            </div>
        </nav >
    );
};

export default Navbar;
