import { motion, AnimatePresence } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import { FaArrowUp } from 'react-icons/fa';
import gallary2 from '../../assets/gallary/gallary2.webp'
import gallary3 from '../../assets/gallary/gallary3.webp'
import gallary4 from '../../assets/gallary/gallary4.webp'
import gallary5 from '../../assets/gallary/gallary5.webp'
import gallary6 from '../../assets/gallary/gallary6.webp'
import gallary7 from '../../assets/gallary/gallary7.webp'
import gallary8 from '../../assets/gallary/gallary8.webp'
import gallary9 from '../../assets/gallary/gallary9.webp'
import gallary10 from '../../assets/gallary/gallary10.webp'
import gallary11 from '../../assets/gallary/gallary11.webp'
import gallary12 from '../../assets/gallary/gallary12.webp'
import gallary13 from '../../assets/gallary/gallary13.webp'
import gallary14 from '../../assets/gallary/gallary14.webp'
import gallary15 from '../../assets/gallary/gallary15.webp'
import gallary16 from '../../assets/gallary/gallary16.webp'
import { Link } from "react-router-dom";
import bgVdo from '../../assets/background_vdo3.mp4'

const Hero = () => {
    const [showButton, setShowButton] = useState(false);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    const handleScroll = () => {
        const offset = 300;
        if (window.pageYOffset > offset) {
            setShowButton(true);
        } else {
            setShowButton(false);
        }
    };

    // Event listener for scroll
    if (typeof window !== 'undefined') {
        window.addEventListener('scroll', handleScroll);
    }
    return (
        <div className="relative bg1">
            <div className="video-container">
                <video autoPlay loop muted className="video-background">
                    <source src={bgVdo} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
            </div>
            <section className="w-full px-5 py-20 grid grid-cols-1 md:grid-cols-2 items-center gap-8 max-w-7xl mx-auto z-10">
                {showButton && (
                    <button
                        onClick={scrollToTop}
                        className="bg-navyBlue text-white font-medium py-3 px-5 rounded-lg shadow-md fixed bottom-10 right-5 md:bottom-10 md:right-10 transition-all hover:bg-hoverColor active:scale-95 z-50"
                    >
                        <FaArrowUp className="inline-block " />
                    </button>
                )}
                <div className="relative z-10">
                    <span className="block mb-4 text-xs md:text-sm text-amber-100 font-medium">
                        Sailors Connected, Dreams Navigated.
                    </span>
                    <h3 className="text-4xl md:text-6xl text-cyan-100 font-semibold">
                        Setting Sail to Success
                    </h3>
                    <p className="text-base md:text-lg text-cyan-200 my-4 md:my-6">
                        Expertly Uniting Crew Manning, Technical Excellence, and Masterful Management for Seamless Operations.
                    </p>
                    <Link to='/career'>
                        <button className="bg-yellow-50 font-medium py-2 px-4 rounded transition-all hover:bg-hoverColor active:scale-95">
                            Explore Career In Merchant Navy
                        </button>
                    </Link>
                </div>
                <ShuffleGrid />
            </section>
        </div>
    );
};

const shuffle = (array) => {
    let currentIndex = array.length,
        randomIndex;

    while (currentIndex != 0) {
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;

        [array[currentIndex], array[randomIndex]] = [
            array[randomIndex],
            array[currentIndex],
        ];
    }

    return array;
};



const ShuffleGrid = () => {
    const timeoutRef = useRef(null);
    const [currentSquareIndex, setCurrentSquareIndex] = useState(0);

    const squareData = [
        {
            id: 2,
            src: gallary2,
        },
        {
            id: 3,
            src: gallary3,
        },
        {
            id: 4,
            src: gallary4,
        },
        {
            id: 5,
            src: gallary5,
        },
        {
            id: 6,
            src: gallary6
        },
        {
            id: 7,
            src: gallary7
        },
        {
            id: 8,
            src: gallary8
        },
        {
            id: 9,
            src: gallary9
        },
        {
            id: 10,
            src: gallary10
        },
        {
            id: 11,
            src: gallary11
        },
        {
            id: 12,
            src: gallary12
        },
        {
            id: 13,
            src: gallary13
        },
        {
            id: 14,
            src: gallary14
        },
        {
            id: 15,
            src: gallary15
        },
        {
            id: 16,
            src: gallary16
        },
    ];
    useEffect(() => {
        // Start the animation
        timeoutRef.current = setTimeout(() => {
            setCurrentSquareIndex((prevIndex) => (prevIndex + 1) % squareData.length);
        }, 3000);

        // Cleanup the timeout on component unmount
        return () => {
            clearTimeout(timeoutRef.current);
        };
    }, [currentSquareIndex]);

    return (
        <div className="border border-[5px] p-2">
            <div className="relative h-[450px] overflow-hidden">
                <AnimatePresence mode="wait">
                    <motion.div
                        key={squareData[currentSquareIndex].id}
                        initial={{ opacity: 0.5, x: "100%" }}
                        animate={{ opacity: 1, x: "0%" }}
                        exit={{ opacity: 0, x: "-100%" }}
                        transition={{ duration: 0.2, type: "ease" }}
                        className="absolute top-0 left-0 w-full h-full"
                        style={{
                            backgroundImage: `url(${squareData[currentSquareIndex].src})`,
                            backgroundSize: "cover",
                        }}
                    ></motion.div>
                </AnimatePresence>
            </div>
        </div>
    );
};

export default Hero;